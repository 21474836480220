/**
 * Returns an array from comma seperated text
 *
 * @param input string
 * @returns array
 */
export function textToArray(input: string): Array<string> {
  const listItems = input.split(',');
  return listItems.map((li) => li.trim());
}
