import { ToolkitItem } from '@/lib/sanity/queries/get-toolkit-item';
import { urlForImage } from '@/lib/sanity/sanity';
import { styled } from '@/stitches.config';
import NextImage from 'next/image';

type ToolkitCoverProps = {
  toolkitCoverImage: ToolkitItem['cover'];
};

export function ToolkitCoverImage({ toolkitCoverImage }: ToolkitCoverProps) {
  if (!toolkitCoverImage?.asset) return null;

  const imageUrl = urlForImage(toolkitCoverImage.asset).url();

  if (!imageUrl) return null;

  return (
    <Wrapper>
      <CoverImage
        placeholder="blur"
        blurDataURL={toolkitCoverImage?.asset?.metadata?.lqip}
        src={imageUrl}
        layout="fill"
        priority
      />
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  overflow: 'hidden',
  position: 'relative',
  aspectRatio: '16 / 9',
  '@supports not (aspect-ratio: 16 / 9)': {
    paddingBottom: '56.25%',
  },
  '&::after': {
    content: '',
    position: 'absolute',
    zIndex: '$default',
    top: 0,
    left: 0,
    right: 0,
    height: '$10',
  },
});

const CoverImage = styled(NextImage, {
  objectFit: 'cover',
  position: 'absolute',
  inset: 0,
});
